/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect } from 'react';
import {
  Box, Grid,
  makeStyles,
  Table,
  TableBody,
  TableContainer,
  Paper,
  Switch,
} from '@material-ui/core';

import { useMutation } from '@tanstack/react-query';
import PageBanner from '../../components/utils/PageBanner';
import GraphqlService from '../../service/graphqlService';
import { useSnackbar } from '../../providers/SnackbarContext';
import useConfig from '../../hooks/useGetConfig';
import { StyledTableCell, StyledTableRow } from '../../components/utils/StyledTable';

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: theme.palette.background.typography,
    padding: 16,
  },
  tableButton: {
    height: 48,
    justifyContent: 'center',
    margin: 4,
    color: 'white',
    borderRadius: 12,
  },
  mainTable: {
    borderRadius: 12,
    boxShadow: theme.customShadows.noTopShadow,
  },
  separate: {
    marginBottom: theme.spacing(2),
  },
}));

export default function PortalConfig() {
  const classes = useStyles();
  const { configData } = useConfig();

  const updateConfigMutation = useMutation((variables) => GraphqlService.updateConfig(variables.config));
  const openSnackBar = useSnackbar();

  const [maintenanceMode, setMaintenanceMode] = useState(false);

  useEffect(() => {
    if (configData && configData.maintenance !== undefined) {
      setMaintenanceMode(configData.maintenance === 'true');
    }
  }, [configData]);

  const handleSwitchChange = async (event) => {
    const newValue = event.target.checked;
    try {
      await updateConfigMutation.mutateAsync({
        config: JSON.stringify({
          maintenance: newValue.toString(),
        }),
      });
      openSnackBar('Config Updated', 'info');
    } catch (error) {
      openSnackBar(error.message, 'error');
    }
  };

  return (
    <Box>
      <PageBanner title="CONFIG" />
      <Grid className={classes.background} container spacing={0} direction="column" alignItems="stretch">
        <Grid item>
          <TableContainer component={Paper} className={classes.mainTable}>
            <Table aria-label="simple table">
              <TableBody>
                <StyledTableRow>
                  <StyledTableCell align="center">Maintenance Mode</StyledTableCell>
                  <StyledTableCell align="center">
                    <Switch
                      checked={maintenanceMode}
                      onChange={handleSwitchChange}
                      name="maintenanceMode"
                      color="primary"
                    />
                  </StyledTableCell>
                </StyledTableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Box>
  );
}
