/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
import {
  Box,
  Checkbox,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  makeStyles,
  Typography,
  Input,
} from '@material-ui/core';
import React, {
  useContext, useState, useEffect,
} from 'react';
import { useHistory, useRouteMatch, useParams } from 'react-router-dom';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { ExpandMore, ExpandLess } from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';
import PageBanner from '../../components/utils/PageBanner';
// Local imports
import { useProject } from '../../config/ProjectContext';
import SelectedObjectsToDeployContext from '../../config/SelectedAssetsContext';
import AssetDetail from '../../components/AssetList/AssetDetail';
import GraphqlService from '../../service/graphqlService';
import YesNoDialog from '../../components/utils/YesNoDialog';
import {
  CREATE_NON_GLOBAL_DEPLOY_PERMISSION,
} from '../../utils/constants';
import { ReactComponent as UploadLogo } from '../../assets/ic_file_upload.svg';
import MessageCard from '../../components/utils/MessageCard';
import { ReactComponent as DeleteIcon } from '../../assets/ic_bin_white.svg';
import ProgressDialog from '../../components/utils/ProgressDialog';
import PaginationComponent from '../../components/Pagination';
import { useSnackbar } from '../../providers/SnackbarContext';
import { formatToPrintableDate } from '../../utils/dateUtils';
import { StyledTableCell, StyledTableRow } from '../../components/utils/StyledTable';
import JsonPopup from '../../components/JsonPopup';

export default function AssetList() {
  const useStyles = makeStyles((theme) => ({
    separate: {

    },
    pagination: {
      background: theme.palette.background.paper,
      borderRadius: 12,
      boxShadow: theme.customShadows.regularShadow,
      color: theme.palette.primary.text,
      float: 'right',
      padding: 0,
      width: '100%',
    },
    background: {
      backgroundColor: theme.palette.background.typography,
      padding: 16,
    },
    button: {
      float: 'right',
      margin: '0 0 16px 24px',
    },
    deployButton: {
      float: 'left',
      margin: '0 0 16px 24px',
    },
    pageSizeTitle: {
      fontSize: 14,
      paddingRight: '24px',
    },
    paginationRoot: {
      display: 'flex',
      justifyContent: 'flex-end',
      width: '100%',
      padding: theme.spacing(2),
    },
    selectPaginationSize: {
      marginRight: '8px',
      borderRadius: 12,
    },
    paginationSizeLabel: {
      paddingRight: '12px',
      fontSize: 16,
    },
    tableIcons: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      paddingBottom: 16,
      flexWrap: 'wrap',
    },
    tableButton: {
      width: '100%',
      height: 48,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    refreshButton: {
      height: 48,
      justifyContent: 'center',
      margin: 4,
      color: 'white',
      borderRadius: 12,
    },
    assetTypeFilterStyle: {
      minWidth: 210,
    },
    descriptionFilterStyle: {
      minWidth: 310,
      marginRight: 4,
      selfAlign: 'center',
    },
    orderDateStyle: {
      minWidth: 130,
    },
    table: {
      borderRadius: 12,
      boxShadow: theme.customShadows.noTopShadow,
    },
    selectedItems: {
      fontSize: 14,
      color: theme.palette.text.secondary,
      marginBottom: 2,
    },
    filters: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginBottom: theme.spacing(2),
      alignItems: 'flex-end',
    },
    descriptionFormControl: {
      minWidth: 250,
    },
  }));

  // STATE________________________________

  //  state values;
  const { project } = useProject();

  const [yesNoDialogOpen, setYesNoDialogOpen] = useState(false);
  const [yesNoDialogText, setYesNoDialogText] = useState('');
  const { selectedObjectsToDeploy: selectedAssets, setSelectedObjectsToDeploy: setSelectedAssets } = useContext(SelectedObjectsToDeployContext);
  const [sortDirection, setSortDirection] = useState('DESC');
  const [selectedAssetTypes, setSelectedAssetTypes] = useState([]);
  const [assetTypeFilter, setAssetTypeFilter] = useState();
  const [filterByAssetDescriptionKey, setFilterByAssetDescriptionKey] = useState('');
  const [filterByAssetDescriptionValue, setFilterByAssetDescriptionValue] = useState('');
  const [debouncedFilterAssetDescription, setDebouncedFilterAssetDescription] = useState(filterByAssetDescriptionValue);

  const [lastAssetTypeFilter, setLastAssetTypeFilter] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const history = useHistory();
  const match = useRouteMatch();
  const { fileId: fileIdParam } = useParams();
  const [lastProjectCode, setLastProjectCode] = useState(null);

  const [expandedRow, setExpandedRow] = useState(null);

  const openSnackBar = useSnackbar();

  const getType = assetTypeFilter === null ? null : assetTypeFilter;

  const fetchAssets = async () => {
    if (project?.code !== lastProjectCode) {
      setCurrentPage(0);
      setLastProjectCode(project?.code);
      setSelectedAssetTypes([]);
      setAssetTypeFilter(null);
      setSelectedAssets([]);
    } else if (assetTypeFilter !== lastAssetTypeFilter) {
      setCurrentPage(0);
      setLastAssetTypeFilter(assetTypeFilter);
      setSelectedAssets([]);
    }

    return GraphqlService.listAssetsNotDeleted({
      projectId: project?.code,
      page: currentPage,
      pageSize,
      sortDirection,
      type: getType,
      assetDescriptionValue: filterByAssetDescriptionKey ? debouncedFilterAssetDescription : undefined,
      assetDescriptionKey: debouncedFilterAssetDescription ? filterByAssetDescriptionKey : undefined,
    });
  };

  const getAsset = async () => GraphqlService.getAsset({
    projectId: project?.code,
    fileId: fileIdParam,
  });

  const {
    data: assetFromParams,
    error: errorFetchingAsset,
    isLoading: isLoadingAsset,
  } = useQuery(['asset', fileIdParam], () => getAsset(), {
    enabled: !!fileIdParam && !!project?.code,
    cacheTime: 0,
    select: (res) => {
      try {
        const parsedDescription = res.data.assetDescription ? JSON.parse(res.data.assetDescription) : {};
        return { ...res.data, assetDescription: parsedDescription };
      } catch (error) {
        return res.data;
      }
    },
  });

  const {
    data: assets,
    error: errorFetchingAssets,
    refetch: refetchAssets,
    isLoading,
  } = useQuery(['assets', project?.code, project?.permission, currentPage, pageSize, sortDirection, assetTypeFilter, debouncedFilterAssetDescription, debouncedFilterAssetDescription], () => fetchAssets(), {
    enabled: !!project && !!project?.code && !fileIdParam,
    cacheTime: 0,
    select: (assetsResponse) => {
      const transformedAssets = assetsResponse.data.map((asset) => {
        try {
          const parsedDescription = asset.assetDescription ? JSON.parse(asset.assetDescription) : {};
          return { ...asset, assetDescription: parsedDescription };
        } catch (error) {
          return asset;
        }
      });
      return { ...assetsResponse, data: transformedAssets };
    },
  });

  const fetchAssetTypes = async () => GraphqlService.getAssetTypes({
    projectId: project?.code,
  });

  const {
    data: assetTypes,
    error: errorFetchingAssetTypes,
    isLoading: isLoadingAssetTypes,
  } = useQuery(['assetTypes', project?.code, project?.permission, currentPage, pageSize, sortDirection], () => fetchAssetTypes({
    projectId: project?.code,
  }), {
    enabled: !!project,
    cacheTime: 0,
  });

  useEffect(() => {
    if (errorFetchingAssetTypes) {
      openSnackBar('Error fetching Asset Types', 'error');
    }
  }, [errorFetchingAssetTypes]);

  // fetch the assets when loading the page
  useEffect(() => {
    if (project?.code) {
      setSelectedAssets([]);
      refetchAssets();
    }
  }, []);

  useEffect(() => {
    // Set a timeout to update the debounced filter value
    const handler = setTimeout(() => {
      setDebouncedFilterAssetDescription(filterByAssetDescriptionValue);
    }, 1000);

    return () => {
      clearTimeout(handler);
    };
  }, [filterByAssetDescriptionValue]);

  const classes = useStyles();

  const queryClient = useQueryClient();

  const deleteAssetsMutation = useMutation(
    (deleteAssetInput) => GraphqlService.deleteAsset(project.code, deleteAssetInput),
    {
      onSuccess: (responsesArray) => {
        const showPopup = responsesArray.find((r) => r.statusCode !== null);
        if (showPopup) {
          const messages = [];
          responsesArray.map((r) => {
            messages.push(r.message);
            return 0;
          });
          openSnackBar(messages.join('\n'), 'error');
        } else {
          openSnackBar('Assets deleted!', 'success');
          setSelectedAssets([]);
          queryClient.invalidateQueries('assets');
        }
      },
      onError: (error) => {
        openSnackBar(`Error deleting asset: ${error.message}`, 'error');
      },
    },
  );

  const onClickNewAsset = () => {
    history.push(`${match.url}/create`);
  };

  const onClickNewDeployment = () => {
    if (selectedAssets.length <= 0) return;
    setSelectedAssets(selectedAssets);
    if (selectedAssets.length === 1) {
      history.push('/assets/deployments/create');
    }
    if (selectedAssets.length > 1) {
      history.push('/bundles/create/asset');
    }
  };

  const onClickDeleteAssets = () => {
    setYesNoDialogText(`You are about to delete ${selectedAssets?.length} assets. Are you sure?`);
    setYesNoDialogOpen(true);
  };

  const confirmDeleteAssets = () => {
    const deleteAssetInput = selectedAssets.map((asset) => ({
      fileId: asset.fileID,
      chunks: asset.chunks,
    }));

    deleteAssetsMutation.mutate(deleteAssetInput);
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value - 1);
  };

  function renderContent() {
    const handleSelectAll = () => {
      if (selectedAssets.length === assets?.data?.length) {
        setSelectedAssets([]);
      } else {
        setSelectedAssets(assets?.data || []);
      }
    };

    const handleRowSelect = (asset) => {
      const selectedIndex = selectedAssets.findIndex((a) => a.fileID === asset.fileID);
      const newSelectedAssets = [...selectedAssets];
      if (selectedIndex === -1) {
        newSelectedAssets.push(asset);
      } else {
        newSelectedAssets.splice(selectedIndex, 1);
      }
      setSelectedAssets(newSelectedAssets);
    };

    const isRowSelected = (asset) => selectedAssets.some((a) => a.fileID === asset.fileID);

    const handleSelectChange = (event) => {
      setPageSize(event.target.value);
      setCurrentPage(0); // Reset to the first page when page size changes
    };

    const handleOrderByChange = (event) => {
      setSortDirection(event.target.value);
    };

    const handleAssetTypeFilterChange = (event) => {
      const { value } = event.target;
      setSelectedAssetTypes(value);
      setAssetTypeFilter(value.join(',').toString());
      setCurrentPage(0); // Reset to the first page when filter changes
    };

    const handleRowExpand = (event, index) => {
      event.stopPropagation();
      setExpandedRow((prevExpandedRow) => (prevExpandedRow === index ? null : index));
    };

    if (errorFetchingAssets) {
      return <MessageCard message={`Error: ${errorFetchingAssets.message}`} />;
    }

    if (errorFetchingAsset) {
      return <MessageCard message={`Error: ${errorFetchingAsset.message}`} />;
    }

    if (assetFromParams) {
      return (
        <AssetDetail
          asset={assetFromParams}
          expandedProp={!!assetFromParams}
        />
      );
    }

    return (
      <>
        {(((isLoading || !project?.code) && !fileIdParam) || ((isLoadingAsset || !project?.code) && !!fileIdParam)) && (
          <ProgressDialog open header="Retrieving assets, please wait" />
        )}
        <Grid item xs={12} className={classes.separate}>
          <Grid container className={classes.filters} spacing={1}>

            <Grid item xs={12} sm="auto">
              <FormControl variant="filled" className={classes.descriptionFormControl}>
                <InputLabel className={classes.paginationSizeLabel}>Description Field</InputLabel>
                <Select
                  value={filterByAssetDescriptionKey}
                  onChange={(e) => setFilterByAssetDescriptionKey(e.target.value)}
                  displayEmpty
                  className={classes.selectPaginationSize}
                >
                  <MenuItem value={undefined} key="NoValue">Not filter </MenuItem>
                  <MenuItem value="Version" key="Version">Version</MenuItem>
                  <MenuItem value="Description" key="Description">Description</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {(filterByAssetDescriptionKey === 'Version' || filterByAssetDescriptionKey === 'Description') && (
              <Grid item xs={12} sm="auto">
                {/* Release Description Value Filter */}
                <FormControl variant="outlined" className={classes.descriptionFormControl}>
                  <InputLabel>Description Value</InputLabel>
                  <Input
                    value={filterByAssetDescriptionValue}
                    onChange={(e) => setFilterByAssetDescriptionValue(e.target.value)}
                  />
                </FormControl>
              </Grid>
            )}

            <Grid item xs={12} sm="auto">
              <FormControl variant="filled" className={classes.orderDateStyle}>
                <InputLabel className={classes.paginationSizeLabel}>Sort Direction</InputLabel>
                <Select
                  value={sortDirection}
                  onChange={handleOrderByChange}
                  displayEmpty
                  className={classes.selectPaginationSize}
                >
                  <MenuItem value="ASC" key="ASC">ASC</MenuItem>
                  <MenuItem value="DESC" key="DESC">DESC</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {
              assetTypes?.data?.length > 0 && !isLoadingAssetTypes && (
                <Grid item xs={12} sm="auto">
                  <FormControl variant="filled" className={classes.assetTypeFilterStyle}>
                    <InputLabel>Asset Type</InputLabel>
                    <Select
                      multiple
                      value={selectedAssetTypes}
                      onChange={handleAssetTypeFilterChange}
                      className={classes.selectPaginationSize}
                      renderValue={(selected) => selected.join(', ')}
                    >
                      {assetTypes?.data.map((assetType) => (
                        <MenuItem key={assetType} value={assetType}>
                          <Checkbox checked={selectedAssetTypes.indexOf(assetType) > -1} />
                          <ListItemText
                            primary={assetType}
                            primaryTypographyProps={{ component: 'span' }} // Add this line
                          />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

              )
            }

          </Grid>
        </Grid>

        <div>
          <Typography component="div" className={classes.selectedItems}>
            {`${selectedAssets.length || 0} ${selectedAssets.length === 1 ? 'asset' : 'assets'} selected`}
          </Typography>
        </div>
        <TableContainer component={Paper} className={classes.table}>
          <Table aria-label="assets table">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell padding="checkbox">
                  <Checkbox
                    indeterminate={selectedAssets.length > 0 && selectedAssets.length < assets?.data?.length}
                    checked={selectedAssets.length === assets?.data?.length}
                    onChange={handleSelectAll}
                  />
                </StyledTableCell>
                <StyledTableCell>Type</StyledTableCell>
                <StyledTableCell>Version</StyledTableCell>
                <StyledTableCell>Created Date</StyledTableCell>
                <StyledTableCell />
                <StyledTableCell />
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {assets?.data?.map((asset, index) => (
                <React.Fragment key={asset.fileID}>
                  <StyledTableRow
                    selected={isRowSelected(asset)}
                    onClick={() => handleRowSelect(asset)}
                    aria-checked={isRowSelected(asset)}
                    role="checkbox"
                  >
                    <StyledTableCell padding="checkbox">
                      <Checkbox
                        checked={isRowSelected(asset)}
                        onClick={(event) => {
                          event.stopPropagation();
                          handleRowSelect(asset);
                        }}
                      />
                    </StyledTableCell>
                    <StyledTableCell>{asset.type}</StyledTableCell>
                    <StyledTableCell>
                      {asset.assetDescription?.Version
                        ? `v.${asset.assetDescription.Version}`
                        : 'Not available'}
                    </StyledTableCell>
                    <StyledTableCell>{formatToPrintableDate(asset.dateTime)}</StyledTableCell>

                    <StyledTableCell>
                      {/* copy asset json with a button, align to the right */}
                      <Box display="flex" justifyContent="flex-end" width="100%">
                        <JsonPopup
                          title="Asset JSON"
                          jsonObject={typeof asset === 'object' && asset !== null ? asset : {}}
                        />
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell>
                      <IconButton onClick={(event) => handleRowExpand(event, index)}>
                        {expandedRow === index ? <ExpandLess /> : <ExpandMore />}
                      </IconButton>
                    </StyledTableCell>
                  </StyledTableRow>
                  {expandedRow === index && (
                    <StyledTableRow>
                      <StyledTableCell colSpan={6}>
                        <AssetDetail asset={asset} />
                      </StyledTableCell>
                    </StyledTableRow>
                  )}

                </React.Fragment>
              ))}
            </TableBody>
          </Table>
          {assets?.pagination && (
            <PaginationComponent
              pageSize={pageSize}
              handleSelectChange={handleSelectChange}
              pagination={assets.pagination}
              handlePageChange={handlePageChange}
            />
          )}
        </TableContainer>

        {
          (!assets || !assets?.data || assets?.data?.length === 0) && !isLoading && !isLoadingAssetTypes
            ? <MessageCard message="No assets retrieved" /> : <></>
        }

      </>
    );
  }

  return (
    <Box>
      <YesNoDialog
        open={yesNoDialogOpen}
        setOpen={setYesNoDialogOpen}
        header="Delete asset"
        body={yesNoDialogText}
        action={() => confirmDeleteAssets()}
        actionName="Yes"
        id="confirmationDeleteAsset"
      />

      <PageBanner
        title="ASSETS"
        id="btnNewAsset"
        buttons={['ADD ASSETS', selectedAssets.length > 1 ? 'CREATE BUNDLE AND DEPLOY' : 'CREATE DEPLOYMENT', 'DELETE ASSETS']}
        buttonIcons={[<AddIcon />, <UploadLogo />, <DeleteIcon />]}
        buttonDisabled={[false, selectedAssets.length <= 0, selectedAssets.length <= 0]}
        onClicks={[onClickNewAsset, onClickNewDeployment, onClickDeleteAssets]}
        buttonPermission={CREATE_NON_GLOBAL_DEPLOY_PERMISSION}
      />

      <Grid
        className={classes.background}
        container
        spacing={0}
        direction="column"
        alignItems="stretch"
      >

        <div
          className={classes.separate}
        >
          {renderContent()}
        </div>
      </Grid>
    </Box>
  );
}
