/* eslint-disable no-unused-vars */
import React, { useRef } from 'react';
import {
  Grid,
  Table,
  TableHead,
  TableBody,
  TableCell,
  Box,
  Dialog,
  Button,
  DialogContent,
  Typography,
  DialogActions,
  makeStyles,
  IconButton,
  TableContainer,
  Paper,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import BundleDetail from './BundleDetail';
import { StyledTableCell, StyledTableRow } from '../../components/utils/StyledTable';

const useStyles = makeStyles(() => ({
  customAlert: {
    padding: 20,
  },
  dialogTitleContainer: {
    backgroundColor: '#f44336',

  },
  dialogTitleText: {
    color: 'white',
    padding: 10,
    flex: 1,
  },
  closeButton: {
    color: 'white',
  },

}));

export default function DeleteBundlesAlert({
  open, setOpen, bundlesDeleted, bundleNotDeleted, errorBundleNotDeleted,
}) {
  const classes = useStyles();

  const dialogRef = useRef(null);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog
        open={open}
        ref={dialogRef}
        onClose={handleClose}
        aria-labelledby="bundle-detail-dialog-title"
        maxWidth="md"
        fullWidth
      >

        <Box className={classes.dialogTitleContainer} display="flex" justifyContent="space-between">
          <Typography className={classes.dialogTitleText}>
            ERROR DELETING BUNDLES
          </Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <DialogContent>

          {bundlesDeleted?.length > 0 && (
            <Grid item xs={12}>
              <Typography variant="h6">Bundles Deleted</Typography>
              <TableContainer component={Paper} className={classes.table}>
                <Table>
                  <TableHead>
                    <StyledTableRow>
                      <StyledTableCell>Title</StyledTableCell>
                      <StyledTableCell>Description</StyledTableCell>
                      <StyledTableCell>Bundle ID</StyledTableCell>
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    {bundlesDeleted?.map((bundle) => (
                      <StyledTableRow key={bundle.releaseID}>
                        <StyledTableCell>{bundle.title}</StyledTableCell>
                        <StyledTableCell>{bundle.releaseDescription?.description}</StyledTableCell>
                        <StyledTableCell>{bundle.releaseID}</StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          )}

          {bundleNotDeleted?.releaseID && (
            <Box m={2}>
              <Typography variant="h6">Bundle Not Deleted</Typography>
              <TableContainer component={Paper} className={classes.table}>
                <Table>
                  <TableHead>
                    <StyledTableRow>
                      <StyledTableCell>Title</StyledTableCell>
                      <StyledTableCell>Description</StyledTableCell>
                      <StyledTableCell>Bundle ID</StyledTableCell>
                      <StyledTableCell />
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>

                    <StyledTableRow key={bundleNotDeleted.releaseID}>
                      <StyledTableCell>{bundleNotDeleted.title}</StyledTableCell>
                      <StyledTableCell>{bundleNotDeleted.releaseDescription?.description}</StyledTableCell>
                      <StyledTableCell>{bundleNotDeleted.releaseID}</StyledTableCell>
                      <StyledTableCell>
                        <BundleDetail releaseID={bundleNotDeleted.releaseID} />
                      </StyledTableCell>
                    </StyledTableRow>

                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )}
          <Box m={2}>

            <Typography variant="h6">Error</Typography>

            <Box
              width="900px" // Fixed width to contain the text without horizontal scrolling
              bgcolor="background.paper"
              style={{
                whiteSpace: 'normal', // Allows text to wrap onto the next line
                wordWrap: 'break-word', // Ensures long words break to fit the width
                overflowWrap: 'break-word', // Wraps long strings
              }}
            >
              <Typography>{errorBundleNotDeleted}</Typography>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" className={classes.button}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
DeleteBundlesAlert.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  bundlesDeleted: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  bundleNotDeleted: PropTypes.object.isRequired,
  errorBundleNotDeleted: PropTypes.string.isRequired,
};
