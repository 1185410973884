/* eslint-disable no-unused-vars */
import React, { useState, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  IconButton,
  makeStyles,
  Typography,
  CircularProgress,
  Grid,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  InputLabel,
  TextField,
} from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { useQuery } from '@tanstack/react-query';
import GraphqlService from '../../service/graphqlService';
import { useProject } from '../../config/ProjectContext';
import ProgressDialog from '../../components/utils/ProgressDialog';

const useStyles = makeStyles((theme) => ({
  dialogTitleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    backgroundColor: '#F3916A',
    color: theme.palette.primary.contrastText,
  },
  dialogTitleText: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '1.5rem',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  detailTitleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  titleDivide: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  table: {
    marginTop: theme.spacing(2),
  },
  detailIcon: {
    padding: 0,
  },
  button: {
    color: theme.palette.primary.contrastText,
    // blue
    backgroundColor: '#3F51B5',
    '&:hover': {
      backgroundColor: '#303F9F',
    },
    borderRadius: '5px',
    padding: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
  },
}));

function BundleDetail({ releaseID }) {
  const classes = useStyles();

  const { project } = useProject();

  const { data: bundleDetail, isLoading: loading } = useQuery(
    ['bundleDetail', releaseID],
    () => GraphqlService.getBundle({ projectId: project?.code, releaseID }),
    {
      enabled: !!project?.code,
    },
  );

  const { data: bundleDeployments, isLoading: bundleDeploymentsLoading } = useQuery(
    ['bundleDeployments', releaseID],
    () => GraphqlService.getBundleDeployments({ projectId: project?.code, releaseID }),
    {
      enabled: !!project?.code,
    },
  );

  const { data: bundleWaves, isLoading: bundleWavesLoading } = useQuery(
    ['bundleWaves', releaseID],
    () => GraphqlService.listPaginatedWaves({ projectId: project?.code, releaseID }),
    {
      enabled: !!project?.code,
    },
  );

  const openAsset = (fileId) => {
    const url = `${window.location.origin}/#/assetDetail/${fileId}`;
    window.open(url, '_blank');
  };

  const openDeployment = (deploymentID) => {
    const url = `${window.location.origin}/#/Detail/${deploymentID}`;
    window.open(url, '_blank');
  };

  const openWave = (waveID) => {
    const url = `${window.location.origin}/#/waveDetail/${waveID}`;
    window.open(url, '_blank');
  };

  const getVersionFromString = (stringValue) => {
    const versionMatch = stringValue?.match(/Version=([^,}]*)/);
    if (versionMatch && versionMatch[1]) {
      return versionMatch[1].trim();
    }
    return undefined;
  };

  return (
    <div>

      {loading || bundleDeploymentsLoading || bundleWavesLoading ? (
        <ProgressDialog open header="Retrieving detail, please wait" />
      ) : (
        bundleDetail && (
          <>
            {/* Render the detail of the selected bundle */}
            <Grid container spacing={2}>

              {/* Title */}
              <Grid item xs={12} md={6}>
                <TextField
                  label="Title"
                  value={bundleDetail.title}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  label="Date"
                  value={bundleDetail.dateTime?.toString('dd/MM/yyyy HH:mm:ss')}
                  fullWidth
                />
              </Grid>

              {/* Render the assets of the selected bundle */}
              <Grid item xs={12}>
                <Typography variant="h6">Assets</Typography>
                <TableContainer component={Paper} className={classes.table}>
                  <Table aria-label="selected assets table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Type</TableCell>
                        <TableCell>Version</TableCell>
                        <TableCell>Creation Date</TableCell>
                        {/* <TableCell /> */}
                        <TableCell />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {bundleDetail.fileIDs?.map((asset) => (
                        <TableRow key={asset.fileID}>
                          <TableCell>{asset.type}</TableCell>
                          <TableCell>{getVersionFromString(asset.assetDescription)}</TableCell>
                          <TableCell>{asset.dateTime?.toString('dd/MM/yyyy HH:mm:ss')}</TableCell>
                          <TableCell>
                            <IconButton className={classes.detailIcon} onClick={() => openAsset(asset.fileID)}>
                              <ExitToAppIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>

              {/* Render the deployments of the selected bundle */}
              <Grid item xs={12}>
                <Typography variant="h6">Deployments</Typography>
                <TableContainer component={Paper} className={classes.table}>
                  <Table aria-label="selected assets table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Deployment ID</TableCell>
                        <TableCell>Date</TableCell>
                        <TableCell />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {bundleDeployments?.map((deployment) => (
                        <TableRow key={deployment.deployID}>
                          <TableCell>{deployment.deployID}</TableCell>
                          <TableCell>{deployment.dateTime?.toString('dd/MM/yyyy HH:mm:ss')}</TableCell>
                          <TableCell>
                            <IconButton className={classes.detailIcon} onClick={() => openDeployment(deployment.deployID)}>
                              <ExitToAppIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>

              {/* Render the waves of the selected bundle */}
              <Grid item xs={12}>
                <Typography variant="h6">Waves</Typography>
                <TableContainer component={Paper} className={classes.table}>
                  <Table aria-label="selected assets table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Wave ID</TableCell>
                        <TableCell>Date</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {bundleWaves?.data?.length && bundleWaves.data.map((wave) => (
                        <TableRow key={wave.waveID}>
                          <TableCell>{wave.waveID}</TableCell>
                          <TableCell>{wave.dateTime?.toString('dd/MM/yyyy HH:mm:ss')}</TableCell>
                          <TableCell>{wave.waveDescription?.userDescription}</TableCell>
                          <TableCell>
                            <IconButton className={classes.detailIcon} onClick={() => openWave(wave.waveID)}>
                              <ExitToAppIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>

              {/* Render the description of the selected bundle */}
              <Grid item xs={12}>
                <InputLabel htmlFor="description"> Description</InputLabel>
                <textarea
                  id="description"
                  disabled
                  value={bundleDetail.releaseDescription?.description}
                  rows="4"
                  cols="50"
                  style={{ width: '100%', padding: '8px', marginTop: '8px' }}
                />
              </Grid>
            </Grid>
          </>
        )
      )}

    </div>
  );
}

BundleDetail.propTypes = {
  releaseID: PropTypes.string.isRequired,
};

export default BundleDetail;
