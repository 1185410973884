/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
import {
  Box, LinearProgress, makeStyles, TextField, Checkbox, ListItemText,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import {
  React, useEffect, useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { v4 as uuidv4 } from 'uuid';
import PageBanner from '../../components/utils/PageBanner';
import SimpleAlert from '../../components/utils/SimpleAlert';
// Local imports
import { useProject } from '../../config/ProjectContext';
import DeploymentAccordion from '../../components/DeploymentList/DeploymentAccordion';
import GraphqlService from '../../service/graphqlService';
import YesNoDialog from '../../components/utils/YesNoDialog';
import PaginationComponent from '../../components/Pagination';
import ProgressDialog from '../../components/utils/ProgressDialog';
import { useSnackbar } from '../../providers/SnackbarContext';
import MessageCard from '../../components/utils/MessageCard';

function TabPanel(props) {
  const {
    children, value, index, ...other
  } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  // eslint-disable-next-line react/require-default-props
  children: PropTypes.node,
  // eslint-disable-next-line react/forbid-prop-types
  index: PropTypes.any.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any.isRequired,
};

export default function DeploymentList() {
  const useStyles = makeStyles((theme) => ({
    separate: {
      flexWrap: 'noWrap',
    },
    headerTabPanel: {
      height: 48,
      backgroundColor: theme.palette.background.paper,
    },
    pagination: {
      background: theme.palette.background.paper,
      borderRadius: 12,
      boxShadow: theme.customShadows.regularShadow,
      color: theme.palette.primary.text,
      float: 'right',
      padding: 0,
      width: '100%',
    },
    labelHeaderTabPanel: {
      color: theme.palette.primary.subMain,
      fontFamily: theme.typography.h3.fontFamily,
      fontSize: '16px',
      fontWeight: theme.typography.fontWeightBold,
      letterSpacing: '0',
      lineHeight: '19px',
      textAlign: 'center',
      width: '720px',
      height: '48px',
    },
    background: {
      backgroundColor: theme.palette.background.typography,
    },
    button: {
      float: 'right',
      margin: '0 0 16px 24px',
    },
    tableIcons: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      padding: 24,
      flexWrap: 'wrap',
    },
    selectPaginationSize: {
      marginRight: '8px',
      borderRadius: 12,
    },
    fileTypeFilterStyle: {
      minWidth: 210,
      marginTop: 6,
    },
    orderDateStyle: {
      minWidth: 130,
      marginTop: 6,
    },
    deployIdFilterStyle: {
      minWidth: 450,
      borderRadius: 12,
      marginRight: 8,
    },
  }));

  const [currentPage, setCurrentPage] = useState(0);
  const [deleted, setDeleted] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const { project } = useProject();
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertHeader, setAlertHeader] = useState('');
  const [alertId, setAlertId] = useState('');
  const [alertText, setAlertText] = useState('');
  const [yesNoDialogOpen, setYesNoDialogOpen] = useState(false);
  const [deployToDelete, setDeployToDelete] = useState(() => { });
  const [yesNoDialogText, setYesNoDialogText] = useState('');
  const [sortDirection, setSortDirection] = useState('DESC');
  const [lastProjectCode, setLastProjectCode] = useState(null);
  const [deploymentFileTypeFilter, setDeploymentFileTypeFilter] = useState(null);
  const [selectedDeploymentFileTypes, setSelectedDeploymentFileTypes] = useState([]);
  const [openDeploymentTypeFilter, setOpenDeploymentTypeFilter] = useState(false); // State to control the open state of the dropdown

  const [lastDeploymentFileTypeFilter, setLastDeploymentFileTypeFilter] = useState();
  const openSnackBar = useSnackbar();

  const [filterByDeployId, setFilterByDeployId] = useState('');
  const [_debouncedFilter, setDebouncedFilter] = useState(filterByDeployId);

  const { deploymentID: deploymentIDParam } = useParams();

  useEffect(() => {
    // Set a timeout to update the debounced filter value
    const handler = setTimeout(() => {
      setDebouncedFilter(filterByDeployId);
    }, 1000);

    return () => {
      clearTimeout(handler);
    };
  }, [filterByDeployId]);

  const getFileType = deploymentFileTypeFilter === null ? null : deploymentFileTypeFilter;

  function openAlert(header, text, id) {
    setAlertHeader(header);
    setAlertText(text);
    setAlertOpen(true);
    setAlertId(id);
  }

  const getDeployment = async () => GraphqlService.getDeployment({
    projectId: project?.code,
    deploymentID: deploymentIDParam,
  });

  const {
    data: deploymentFromParams,
  } = useQuery(['deployment', deploymentIDParam], () => getDeployment(), {
    enabled: !!deploymentIDParam && !!project?.code,
    cacheTime: 0,
  });

  const fetchDeployments = async () => {
    if (project?.code !== lastProjectCode || deploymentFileTypeFilter !== lastDeploymentFileTypeFilter) {
      setCurrentPage(0);
      setLastProjectCode(project?.code);
      setLastDeploymentFileTypeFilter(deploymentFileTypeFilter);
    }

    return GraphqlService.listDeployments({
      projectId: project?.code,
      page: currentPage,
      pageSize,
      sortDirection,
      fileType: getFileType,

      deleted,
    });
  };

  const {
    data: deployments,
    error: errorFetchingDeployments,
    isLoading,
    refetch,
  } = useQuery(['deployments', project?.code, project?.permission, currentPage, pageSize, sortDirection, deploymentFileTypeFilter, deleted], () => fetchDeployments({
    projectId: project?.code,
    page: currentPage,
    pageSize: 10,
    sortDirection,
    fileType: getFileType,
    deleted,
  }), {
    enabled: !!project,
    refetchOnWindowFocus: true,
    refetchOnMount: true,
    cacheTime: 0,
  });

  const fetchDeploymentFileTypes = async () => GraphqlService.getDeploymentFileTypes({
    projectId: project?.code,
  });

  const {
    data: deploymentFileTypes,
    error: errorFetchingDeploymentFileTypes,
    isLoading: isLoadingDeploymentFileTypes,
  } = useQuery(['deploymentFileTypes', project?.code, project?.permission, currentPage, pageSize, sortDirection], () => fetchDeploymentFileTypes({
    projectId: project?.code,
  }), {
    enabled: !!project,
    refetchOnWindowFocus: true,
    refetchOnMount: true,
    cacheTime: 0,
  });

  async function deleteDeploy(deploy) {
    try {
      await GraphqlService.deleteDeploy(project?.code, deploy.deploymentID);
      refetch();
    } catch (error) {
      openAlert('Error', `Error deleting deployment for asset ${deploy.file.type}: ${error.message}`, 'closeAlertDeleteDeploymentError');
    }
  }

  useEffect(() => {
    if (errorFetchingDeploymentFileTypes) {
      openSnackBar('Error fetching Deployment File Types', 'error');
    }
  }, [errorFetchingDeploymentFileTypes, openSnackBar]);

  const handleDeletedSelectionChange = (event) => {
    setDeleted(event.target.value);
  };

  const handleOrderByChange = (event) => {
    setSortDirection(event.target.value);
  };

  const handleDeploymentFileTypeFilterChange = (event) => {
    const { value } = event.target;
    // Need to handle the selection as an array
    setSelectedDeploymentFileTypes(value);
    // transform the array in a string with values sepparated by commas
    if (!value) {
      setDeploymentFileTypeFilter(null);
    } else setDeploymentFileTypeFilter(value.join(',').toString());
  };

  const handleClose = () => {
    setOpenDeploymentTypeFilter(false);
  };

  const handleOpenDeploymentTypeFilter = () => {
    setOpenDeploymentTypeFilter(true);
  };

  const onClickDeleteDeploy = (deployId) => {
    const deploy = deployments?.data?.find((d) => d.deploymentID === deployId);
    if (deploy) {
      setDeployToDelete(deploy);
      if (deploy.file && deploy.file.assetDescription) {
        setYesNoDialogText(`You are about to cancel deployment of asset ${deploy.file.type} ${deploy.file.assetDescription.version}. Are you sure?`);
      } else if (deploy.file && deploy.file.metaData) {
        setYesNoDialogText(`You are about to cancel deployment of asset ${deploy.file.type} ${deploy.file.metaData.version}. Are you sure?`);
      } else {
        setYesNoDialogText(`You are about to cancel deployment of asset ${deploy.file.type}. Are you sure?`);
      }
      setYesNoDialogOpen(true);
    } else {
      openAlert('Deployment can\'t be found', 'The requested deployment could\'t be found', 'closeAlertDeleteDeploymentNotFoundError');
    }
  };

  const classes = useStyles();
  const handlePageChange = (event, value) => {
    setCurrentPage(value - 1);
  };

  const handleSelectChange = (event) => {
    setPageSize(event.target.value);
  };

  if (!project || deployments?.length === 0) {
    return <MessageCard message="No deployments retrieved" />;
  }

  if (errorFetchingDeployments) {
    return (
      <SimpleAlert
        open={alertOpen}
        setOpen={setAlertOpen}
        header="Error"
        body={errorFetchingDeployments.message}
        id="closeAlertErrorFetchingDeployments"
      />
    );
  }

  if (deploymentFromParams) {
    return (
      <DeploymentAccordion
        deployment={deploymentFromParams}
        key={deploymentFromParams.deploymentID}
        id={`txtDeployment${uuidv4()}`}
        expandedProp
      />
    );
  }

  return (
    <Box>
      <YesNoDialog
        open={yesNoDialogOpen}
        setOpen={setYesNoDialogOpen}
        header="Cancel deployment"
        body={yesNoDialogText}
        action={() => deleteDeploy(deployToDelete)}
        actionName="Yes"
        id="confirmationDeleteDeploy"
      />
      <SimpleAlert open={alertOpen} setOpen={setAlertOpen} header={alertHeader} body={alertText} id={alertId} />
      <PageBanner
        title="ASSETS DEPLOYMENTS"
      />
      {(isLoading || !project?.code) && (
        <ProgressDialog open header="Retrieving deployments, please wait" />
      )}

      <div className={classes.tableIcons}>

        {
          deploymentFileTypes?.data?.length > 0 && !isLoadingDeploymentFileTypes && (

            <FormControl variant="filled" className={classes.fileTypeFilterStyle}>

              <InputLabel className={classes.paginationSizeLabel}>File Type Filter</InputLabel>
              <Select
                value={selectedDeploymentFileTypes}
                onChange={handleDeploymentFileTypeFilterChange}
                displayEmpty
                multiple
                open={openDeploymentTypeFilter}
                onOpen={handleOpenDeploymentTypeFilter}
                onClose={handleClose}
                className={classes.selectPaginationSize}
                renderValue={(selected) => selected.join(', ')}
              >

                {
                  deploymentFileTypes?.data?.map((deploymentFileType) => (

                    <MenuItem key={deploymentFileType} value={deploymentFileType}>
                      <Checkbox checked={selectedDeploymentFileTypes.indexOf(deploymentFileType) > -1} />
                      <ListItemText primary={deploymentFileType} />
                    </MenuItem>

                  ))
                }

              </Select>
            </FormControl>

          )
        }

        <FormControl variant="filled" className={classes.orderDateStyle}>

          <InputLabel className={classes.paginationSizeLabel}>Status</InputLabel>
          <Select
            value={deleted}
            onChange={handleDeletedSelectionChange}
            displayEmpty
            className={classes.selectPaginationSize}
          >
            <MenuItem value="0" key="ACTIVE">ACTIVE</MenuItem>
            <MenuItem value="1" key="DELETED">DELETED</MenuItem>
          </Select>
        </FormControl>

        <FormControl variant="filled" className={classes.orderDateStyle}>

          <InputLabel className={classes.paginationSizeLabel}>Date</InputLabel>
          <Select
            value={sortDirection}
            onChange={handleOrderByChange}
            displayEmpty
            className={classes.selectPaginationSize}
          >
            <MenuItem value="ASC" key="ASC">ASC</MenuItem>
            <MenuItem value="DESC" key="DESC">DESC</MenuItem>
          </Select>
        </FormControl>

      </div>

      {
        deployments?.data?.map((deployment, i) => (
          <DeploymentAccordion
            deployment={deployment}
            key={deployment.deploymentIDs}
            id={`txtDeployment${i}`}
            deleted={deleted === '1'}
            deleteDeploy={onClickDeleteDeploy}
          />
        ))
      }

      <div className={classes.tableIcons}>
        {deployments?.pagination && (

          <PaginationComponent
            pageSize={pageSize}
            handleSelectChange={handleSelectChange}
            pagination={deployments.pagination}
            handlePageChange={handlePageChange}
          />

        )}
      </div>

    </Box>
  );
}
